import { AxiosResponse } from "axios";
import { safelyParseJson } from "./json";

export interface ClientErrorInfo {
  code?: number;
  message?: string;
  httpCode?: number;
}

export class ClientError extends Error {
  public code: number;
  public message: string;
  public httpCode: number;

  public constructor(info: ClientErrorInfo) {
    const { code, message, httpCode } = info;
    super(message || "Internal error");
    this.name = "ClientError";
    this.code = code || 0;
    this.message = message || "Internal error";
    this.httpCode = httpCode || 0;
  }
}

export const handleErrorResponse = async (resp: AxiosResponse) => {
  const respText = await resp.statusText;
  const respJson = <{ error?: ClientErrorInfo } | null>(
    safelyParseJson(respText)
  );
  if (respJson === null || !respJson.error) {
    throw new ClientError({
      message: `Unknown Error (response: ${respText.slice(0, 128)})`,
    });
  }
  throw new ClientError({
    code: Number(respJson.error.code || 0),
    message: respJson.error.message,
    httpCode: resp.status,
  });
};

export const throwAxiosError = async (err: any) => {
  if (err.response) {
    return err.response;
  }
};

export const throwErrorAlert = async (err: any) => {
  const statusCode = err?.status;
  if (statusCode === 403 || statusCode === 406) {
    return alert(`권한이 없습니다. Secops에 문의하세요`);
  }
  return alert(`${JSON.stringify(err?.statusText)}`);
};
