import { FC, forwardRef } from "react";
import { Box } from "@mui/material";
import React from "react";

type PageProps = {
  title: string;
  children?: React.ReactNode | undefined;
};

// eslint-disable-next-line react/display-name
const Page = forwardRef<FC<PageProps>, PageProps>(
  ({ children, ...other }, ref) => (
    <Box ref={ref} {...other}>
      {children}
    </Box>
  )
);
export default Page;
