/*
 * Copyright 2019 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License"); you may not use this file except
 * in compliance with the License. You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under the
 * License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
 * express or implied. See the License for the specific language governing permissions and
 * limitations under the License.
 */
import { Card, CardContent, Typography, Divider, Box } from "@mui/material";
import React from "react";
import ReactMarkdown from "react-markdown";
import { GoogleLoginButton } from "react-social-login-buttons";

export interface SignInParameters {
  title: string;
  showGoogleLogin: boolean;
  onSignInWithGoogle: () => boolean;
  footer?: string | undefined;
}

export class SignIn extends React.Component<
  SignInParameters,
  { email: string }
> {
  constructor(props: SignInParameters) {
    super(props);
    this.state = {
      email: "",
    };
  }

  handleChange = (event: any) => {
    this.setState({
      email: event.target.value,
    });
  };

  render(): JSX.Element {
    const buttonStyle = {
      fontSize: "medium",
      marginTop: 6,
      marginBottom: 6,
    };
    return (
      <Card>
        <CardContent>
          <Typography variant="h5" component="h2">
            {this.props.title}
          </Typography>
          <Divider sx={{ mx: 2, mt: 1, mb: 1 }}></Divider>
          <Box
            sx={{
              px: 4,
              minHeight: "130px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            {this.props.showGoogleLogin && (
              <GoogleLoginButton
                style={buttonStyle}
                onClick={this.props.onSignInWithGoogle}
              />
            )}
          </Box>
          {this.props.footer && (
            <>
              <Divider sx={{ mx: 2, mt: 1, mb: 1 }}></Divider>
              <ReactMarkdown>{this.props.footer}</ReactMarkdown>
            </>
          )}
        </CardContent>
      </Card>
    );
  }
}
