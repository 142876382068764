export const safelyParseJson = (s: string): object | null => {
  try {
    const parsed = JSON.parse(s);
    if (typeof parsed !== "object") {
      return null;
    }
    return parsed;
  } catch (e) {
    return null;
  }
};
