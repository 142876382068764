/*
 * Copyright 2019 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License"); you may not use this file except
 * in compliance with the License. You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under the
 * License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
 * express or implied. See the License for the specific language governing permissions and
 * limitations under the License.
 */
import React from "react";
import { Container, Box } from "@mui/material";
import { SignIn, SignInParameters } from "./signin";
import { SignInWithEmail, SignInWithEmailParameters } from "./signinwithemail";
import { SignUpWithEmail, SignUpWithEmailParameters } from "./signupwithemail";
import SignOut from "./signout";
import ProgressBar from "./progressbar";
import { Alert, AlertParameters } from "./alert";
import { LoginUiConfigType } from "../common/type";
import { getLoginUiConfig, processUser } from "../apis/api";
// Import Firebase dependencies.
import firebase from "firebase/app";
import "firebase/auth";
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/js/bootstrap.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import { UserCredential, FirebaseAuth, User } from "@firebase/auth-types";
// Import GCIP/IAP module.
import * as ciap from "gcip-iap";

interface Props {
  location?: any;
}

interface AppState {
  mode?: "SIGN_IN" | "SIGN_OUT" | "PROGRESS_BAR" | "NONE";
  alertParams?: AlertParameters;
  signIn?: SignInParameters;
}

const processUserInternal = async (
  authApiKey: string | null,
  uid: string,
  myStorage: Storage,
  config: LoginUiConfigType
) => {
  console.log("processUserInternal called");
  const projectName = config.projectName;
  const token = await processUser(authApiKey, projectName, uid);
  const userToken = token.token;
  myStorage.setItem(`${projectName}.userToken`, userToken);
};
class App
  extends React.Component<Props, AppState>
  implements ciap.AuthenticationHandler
{
  private progressBarTimer: any;
  private ciapInstance: any;
  private config: any;
  private myStorage = window.localStorage;

  constructor(props: Props) {
    super(props);
    this.progressBarTimer = null;
    this.state = {};
  }

  componentDidMount() {
    const apiKey = this.getApiKeyFromQueryParam();
    console.log(`API key = ${apiKey}`);

    getLoginUiConfig(apiKey)
      .then((resp: any) => resp.json())
      .catch((error: any) => {
        throw new Error("Failed to fetch config.");
      })
      .then((config: any) => {
        this.config = config;
        this.ciapInstance = new ciap.Authentication(this);
        if (this.ciapInstance === undefined) {
          throw new Error(
            `Failed to initialize auth instance. config : ${this.config}`
          );
        }
        const p = this.ciapInstance.start();
        return p;
      })
      .catch((error: any) => {
        this.updateError(error);
      });
  }

  render(): JSX.Element {
    const alertParams = this.state.alertParams || {};
    return (
      <Container
        sx={{
          width: "100%",
          textAlign: "center",
          height: "100vh",
          bgcolor: "#eeeeee",
        }}
      >
        <Box
          display="inline-block"
          textAlign="center"
          sx={{
            width: "70%",
            minWidth: "300px",
            maxWidth: "400px",
            mt: "30px",
          }}
        >
          {this.renderCiapComponent()}
          <Alert
            code={alertParams.code}
            message={alertParams.message}
            retry={alertParams.retry}
          />
        </Box>
      </Container>
    );
  }

  private getApiKeyFromQueryParam(): string | null {
    const search = this.props.location.search;
    return new URLSearchParams(search).get("apiKey");
  }

  public getAuth(apiKey: string, tenantId: string | null): FirebaseAuth {
    let auth = null;
    console.log(`getAuth called. apiKey=${apiKey}, tenantId=${tenantId}`);
    const app = firebase.initializeApp(this.config);
    auth = app.auth();
    if (this.config.tenantId) {
      auth.tenantId = this.config.tenantId;
    }
    return auth as any;
  }

  public handleError(error: any) {
    this.updateError(error);
  }

  public selectTenant(
    projectConfig: { projectId: string },
    tenantIds: string[]
  ): Promise<ciap.SelectedTenantInfo> {
    console.log("selectTenant called");
    throw new Error("selectTenant not available");
  }

  public processUser(user: User): Promise<User> {
    console.log("processUser called");
    const authApiKey = this.getApiKeyFromQueryParam();
    const uid = user.uid;
    console.log("before processUserInternal");
    return new Promise((resolve, rejects) => {
      processUserInternal(authApiKey, uid, this.myStorage, this.config)
        .then(() => {
          return user.getIdToken(true);
        })
        .then(() => {
          resolve(user);
        });
    });
  }

  public startSignIn(auth: FirebaseAuth): Promise<UserCredential> {
    console.log("startSignIn called");
    return new Promise((resolve, reject) => {
      this.signIn(
        /* title */
        this.config.loginUi.title,
        /* showGoogleLogin */
        this.config.loginUi.showGoogleLogin,
        /* onSignInWithGoogle */
        () => {
          this.updateError(null);
          auth
            .signInWithPopup(new firebase.auth.GoogleAuthProvider())
            .then((cred: any) => resolve(cred))
            .catch((error: any) => {
              this.updateError(error);
            });
          return false;
        },
        this.config.loginUi.footer
      );
    });
  }

  public completeSignOut(): Promise<void> {
    this.signOut();
    return Promise.resolve();
  }

  public hideProgressBar() {
    clearTimeout(this.progressBarTimer);
    this.hideContainer();
  }

  public showProgressBar() {
    // Show progress bar only if it takes longer than a certain delay.
    // This prevents flicker effects when a transition is quick and a spinner
    // is shown in between.
    this.progressBarTimer = setTimeout(() => {
      this.renderProgressBar();
    }, 1000);
  }

  private updateError(
    error: { code?: string; message?: string; retry?: any } | null
  ) {
    const modifiedState: AppState = {
      alertParams: {
        code: (error && error.code) || undefined,
        message: (error && error.message) || undefined,
        retry: (error && error.retry) || undefined,
      },
      // Keep existing values for the rest of the state.
      mode: this.state.mode,
      signIn: this.state.signIn,
    };
    this.setState(modifiedState);
  }

  private signIn(
    title: string,
    showGoogleLogin: boolean,
    onSignInWithGoogle: () => boolean,
    footer: string
  ) {
    this.setState({
      mode: "SIGN_IN",
      signIn: {
        title,
        showGoogleLogin,
        onSignInWithGoogle,
        footer,
      },
    });
  }

  private signOut() {
    this.setState({
      mode: "SIGN_OUT",
    });
  }

  private renderProgressBar() {
    this.setState({
      mode: "PROGRESS_BAR",
    });
  }

  private hideContainer() {
    this.setState({
      mode: "NONE",
    });
  }

  private renderCiapComponent = (): JSX.Element => {
    switch (this.state.mode) {
      case "SIGN_IN":
        const signIn = this.state.signIn as SignInParameters;
        return (
          <SignIn
            title={signIn.title}
            showGoogleLogin={signIn.showGoogleLogin}
            onSignInWithGoogle={signIn.onSignInWithGoogle}
            footer={signIn.footer}
          />
        );
      case "SIGN_OUT":
        return <SignOut />;
      case "PROGRESS_BAR":
        return <ProgressBar />;
      default:
        return <div></div>;
    }
  };
}

export default App;
