import axios, { AxiosResponse } from "axios";
import { throwAxiosError } from "../common/error";
import { getRequest, postRequest } from "../common/fetch";
import {
  errorMessage,
  failedReturnType,
  getFailedReturnType,
  getSuccessReturnType,
  ResponseType,
  successReturnType,
  UpdatePublicProfileDto,
  UpdateUserPrivateInfoDto,
} from "../common/type";

export const getLoginUiConfig = async (apiKey: any): Promise<Response> => {
  try {
    return await fetch(`/api/login-configurations/${apiKey}`);
  } catch (err) {
    return await throwAxiosError(err);
  }
};

export const processUser = async (
  authApiKey: string | null,
  projectName: string,
  uid: string
) => {
  const processUserRequest = {
    headers: {
      "Content-Type": "application/json",
      "x-projectname": `${projectName}`,
    },
  };
  try {
    const resp = await axios.post(
      `/api/users/${authApiKey}/app-users/${uid}/process`,
      {},
      processUserRequest
    );
    console.log("userToken=", resp.data);
    return resp.data;
  } catch (err) {
    return failedReturnType(err);
  }
};

export const getMe = async (
  projectName: string,
  userToken: string
): Promise<ResponseType> => {
  const getUserInfoRequest = {
    headers: {
      "Content-Type": "application/json",
      "x-projectname": `${projectName}`,
      "x-usertoken": `${userToken}`,
    },
  };
  try {
    const resp = await axios.get("/api/users/me", getUserInfoRequest);
    return getSuccessReturnType(resp);
  } catch (e) {
    return getFailedReturnType(e);
  }
};

export const updateMyPublicProfile = async (
  profile: any, // FIXME
  projectName: string,
  userToken: string
): Promise<ResponseType> => {
  try {
    const resp = await axios.patch(`/api/users/me/public-profile`, profile, {
      headers: {
        "Content-Type": "application/json",
        "x-projectname": `${projectName}`,
        "x-usertoken": `${userToken}`,
      },
    });
    return successReturnType(resp);
  } catch (e: any) {
    return failedReturnType(e);
  }
};

export const updateUserPrivateProfile = async (
  userInfo: UpdateUserPrivateInfoDto,
  projectName: string,
  userToken: string
): Promise<ResponseType> => {
  try {
    const resp = await axios.patch(`/api/users/me/privateProfile`, userInfo, {
      headers: {
        "Content-Type": "application/json",
        "x-projectname": `${projectName}`,
        "x-usertoken": `${userToken}`,
      },
    });
    return successReturnType(resp);
  } catch (e: any) {
    return failedReturnType(e);
  }
};

export const getProjectConfiguration = async (
  projectName: string
): Promise<ResponseType> => {
  try {
    const resp = await axios.get(`/api/project-configurations/${projectName}`);
    return getSuccessReturnType(resp);
  } catch (e: any) {
    return getFailedReturnType(e);
  }
};
