import { AxiosResponse } from "axios";

export const errorMessage = (e: any) =>
  e.response?.data?.description || `${e.message}` || "Unknown error";

export const getSuccessReturnType = (resp: AxiosResponse) => {
  return {
    success: true,
    data: resp.data,
    message: resp.statusText,
  };
};
export const getFailedReturnType = (e: any) => {
  return {
    success: false,
    message: errorMessage(e),
    statusCode: e.response.status,
  };
};
export const successReturnType = (resp: AxiosResponse) => {
  return {
    success: true,
    message: resp.statusText,
  };
};
export const failedReturnType = (e: any) => {
  return {
    success: false,
    message: errorMessage(e),
    statusCode: e.response?.status,
  };
};

export interface ResponseType {
  success: boolean;
  data?: any;
  message: string;
  statusCode?: number;
}

export interface LoginUiConfigType {
  apiKey: string;
  authDomain: string;
  projectId: string;
  loginUi: any;
  tenantId?: string;
  projectName: string;
}

export interface PublicProfileExtraFieldType {
  type: string;
  key: string;
  label: string;
}

export interface ProjectConfigType {
  publicProfileExtraFields: Array<PublicProfileExtraFieldType>;
  homepageUrl: string;
}

export interface UpdatePublicProfileDto {
  nickname?: string;
  extras?: {
    [key: string]: number | string;
  };
}
