import Page from "../common/Page";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Container,
  Grid,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import BuildIcon from "@mui/icons-material/Build";
import EmailIcon from "@mui/icons-material/Email";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import SaveIcon from "@mui/icons-material/Save";
import TagIcon from "@mui/icons-material/Tag";
import React, { useEffect, useState } from "react";
import {
  getMe,
  updateMyPublicProfile,
  getProjectConfiguration,
} from "../apis/api";
import { Controller, useForm } from "react-hook-form";
import ProgressBar from "./progressbar";
import { ProjectConfigType, PublicProfileExtraFieldType } from "../common/type";

type PublicProfileType = {
  nickname: string;
  extras: {
    [key: string]: number | string;
  };
};

type UserType = {
  displayName?: string;
  email: string;
  gcipUid: string;
  roles: Array<string>;
  disabled: boolean;
  publicProfile?: PublicProfileType;
};

export default function Profile() {
  // helper functions
  const getProjectNameFromQueryParam = (): string | null => {
    const search = window.location.search;
    return new URLSearchParams(search).get("projectName");
  };
  const getUserTokenInLs = (projectName: string): string | null => {
    const userTokenInLs = window.localStorage.getItem(
      `${projectName}.userToken`
    );
    return userTokenInLs;
  };
  const appendErrorMessage = (message: string) => {
    setErrorMessages([...errorMessages, message]);
  };
  const isReady = () => {
    return initialMe && errorMessages.length == 0;
  };

  // vars
  const projectName = getProjectNameFromQueryParam() || "";
  const userToken = getUserTokenInLs(projectName);
  const [profileFields, setProfileFields] = useState<
    Array<PublicProfileExtraFieldType>
  >([]);
  const [initialMe, setInitialMe] = useState<UserType>();
  const [errorMessages, setErrorMessages] = useState<Array<string>>([]);
  const [loginLink, setLoginLink] = useState<string>();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [saveButtonText, setSaveButtonText] = useState<string>("Save");
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      nickname: "",
      extras: {},
    },
  });

  // implementations
  useEffect(() => {
    async function fetchAndSetUserProfile() {
      if (!projectName) {
        appendErrorMessage("올바르지 않은 접근입니다. (projectName is empty)");
        return;
      }

      const projectConfigResp = await getProjectConfiguration(projectName);
      if (!projectConfigResp.success) {
        appendErrorMessage(
          "올바르지 않은 접근입니다. (failed to fetch projectConfiguration)"
        );
        return;
      }
      const config = projectConfigResp.data as ProjectConfigType;

      if (!userToken) {
        appendErrorMessage(`로그인 후 이용해 주세요.`);
        if (config.homepageUrl) {
          setLoginLink(config.homepageUrl + "?gcp-iap-mode=GCIP_SIGNOUT");
        }
        return;
      }

      const userResp = await getMe(projectName, userToken);
      if (!userResp.success) {
        const errorMsg = `프로필을 불러올 수 없습니다. (${
          userResp.statusCode
        } - ${userResp.message || "unknown error"})`;
        appendErrorMessage(errorMsg);
        return;
      }
      const me = userResp.data as UserType;

      // done!
      setProfileFields(config.publicProfileExtraFields);
      setInitialMe(me);
      setValue("nickname", me.publicProfile?.nickname || "");
      setValue("extras", me.publicProfile?.extras || {});
    }
    fetchAndSetUserProfile();
  }, []);

  const onSubmit = async (data: any) => {
    setIsSaving(true);
    setSaveButtonText("Save");
    const resp = await updateMyPublicProfile(data, projectName!!, userToken!!);
    setIsSaving(false);
    if (resp.success) {
      setSaveButtonText("Saved!");
    } else {
      setSaveButtonText("Save");
      appendErrorMessage(`프로필 수정 실패 (${resp.message})`);
    }
  };

  return (
    <Page title={`User Profile`}>
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {/* Header section */}
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography variant="h5">My Profile</Typography>
          <Typography variant="caption">
            (Project Name : {projectName})
          </Typography>

          {/* Error section */}
          {!initialMe && errorMessages.length == 0 && <ProgressBar />}
          {errorMessages.length > 0 && (
            <Box sx={{ width: "100%", mt: 2, mb: 1 }}>
              <Card sx={{ backgroundColor: "lightpink" }}>
                <CardContent sx={{ mv: 0.5 }}>
                  {errorMessages.map((errorMsg) => (
                    <Box>
                      <Typography variant="caption" color="error">
                        {errorMsg}
                      </Typography>
                    </Box>
                  ))}
                  {loginLink && (
                    <Link href={loginLink}>
                      <Typography variant="caption" color="primary">
                        (Click here to login again!)
                      </Typography>
                    </Link>
                  )}
                </CardContent>
              </Card>
            </Box>
          )}

          {/* Info section */}
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit(onSubmit)}
            sx={{ mt: 3 }}
          >
            <Card>
              <CardHeader
                title="Login Information"
                titleTypographyProps={{ fontSize: 16, color: "#666666" }}
                sx={{ backgroundColor: "#EEEEEE" }}
              />
              <CardContent sx={{ m: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <AccountCircleIcon
                      sx={{ width: "100%", height: "auto" }}
                    ></AccountCircleIcon>
                  </Grid>
                  <Grid item xs={9}>
                    <Box>
                      <Typography variant="caption">
                        {initialMe?.displayName || "Loading..."}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="caption">
                        <EmailIcon sx={{ verticalAlign: "middle", mr: 1 }} />
                        {initialMe?.email || "Loading..."}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="caption">
                        <TagIcon sx={{ verticalAlign: "middle", mr: 1 }} />
                        {initialMe?.gcipUid || "Loading..."}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box>
                      <Typography variant="caption">
                        <BuildIcon sx={{ verticalAlign: "middle", mr: 1 }} />
                        Granted Roles :
                        {initialMe?.roles?.map((role) => (
                          <Chip label={role} variant="outlined" />
                        ))}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>

            <Card sx={{ mt: 4 }}>
              <CardHeader
                title="User Profile"
                titleTypographyProps={{ fontSize: 16, color: "#666666" }}
                sx={{ backgroundColor: "#EEEEEE" }}
              />
              <CardContent sx={{ m: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Controller
                      name="nickname"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          fullWidth
                          id="nickname"
                          label="Nickname"
                          InputLabelProps={{ shrink: true }}
                          value={value || ""}
                          onChange={onChange}
                          disabled={!isReady()}
                        />
                      )}
                    />
                  </Grid>
                  {profileFields.map((field) => (
                    <Grid item xs={12}>
                      <Controller
                        name={`extras.${field.key}`}
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            fullWidth
                            id={`extras.${field.key}`}
                            label={field.label}
                            InputLabelProps={{ shrink: true }}
                            value={value || ""}
                            onChange={onChange}
                            disabled={!isReady()}
                          />
                        )}
                      />
                    </Grid>
                  ))}
                </Grid>
              </CardContent>
            </Card>

            <LoadingButton
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 4, mb: 4 }}
              disabled={!isReady() || isSaving}
              endIcon={<SaveIcon />}
              loading={isSaving}
              loadingPosition="end"
            >
              {saveButtonText}
            </LoadingButton>
          </Box>
        </Box>
      </Container>
    </Page>
  );
}
